import axios from 'axios';
import config from '@/config/env-constants';

export default {
    baseUrl: 'https://us-central1-ayun-quantity-' + config.currEnv + '.cloudfunctions.net',

    fetchAdminDashboard(currUserId) {
        let url = `${this.baseUrl}/adminDashboard`;
        return axios.post(url, { currUserId: currUserId });
    },

    fetchCompanyDashboard(currUserId, companyId) {
        let url = `${this.baseUrl}/companyDashboard`;
        return axios.post(url, { currUserId: currUserId, companyId: companyId });
    }
}